function Footer() {
  return (
    
    <div className="footer-container">
  <div class="divider light" />
      <span id="copyright">
        Copyright 2024 Dezu</span>
      <span>Produced by Vontos</span>
    </div>
  );
}

export default Footer;
