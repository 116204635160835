import React from "react";
import "../../styles/styles.css"; 

function HKN() {
  return (
    <div>
      <div className="haskel">
      <img src={require("../../assets/images/univ/hkn.png")} 
        alt="hkn"
          className="hkn"
        />
      </div>
    </div>
  );
}

export default HKN;
